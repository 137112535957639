
import { Component, Vue } from 'vue-property-decorator';
import * as policyStore from '@/store/modules/policy';
import * as appStore from '@/store/modules/app';

@Component
export default class Legal extends Vue {
  policyApi = policyStore;

  appState = appStore;

  async showPrivacyPolicyFromServerResourceUriAsync() {
    const { currentLanguage } = this.appState.getters.getAppState;
    await this.policyApi.actions.showPrivacyPolicyFromServerResourceUriAsync(currentLanguage);
    return false;
  }

  async showCookiePolicyFromServerResourceUriAsync() {
    const { currentLanguage } = this.appState.getters.getAppState;
    await this.policyApi.actions.showCookiePolicyFromServerResourceUriAsync(currentLanguage);
    return false;
  }

  async showGdprClientsFromServerResourceUriAsync() {
    const { currentLanguage } = this.appState.getters.getAppState;
    await this.policyApi.actions.showGdprClientsFromServerResourceUriAsync(currentLanguage);
    return false;
  }

  async showGdprEmployeesFromServerResourceUriAsync() {
    const { currentLanguage } = this.appState.getters.getAppState;
    await this.policyApi.actions.showGdprEmployeesFromServerResourceUriAsync(currentLanguage);
    return false;
  }
}
